// Copyright 2022 Merit International Inc. All Rights Reserved

import { Heading } from "@merit/frontend-components";
import { PreLoginLayout } from "../layouts/PreLoginLayout";
import { useAlertStore } from "../stores/alertStore";
import { useApi } from "../api/api";
import { useAuthStore } from "../stores/authStore";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useOrgLogoStore } from "@src/stores/orgLogoStore";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import type { JwtClaims } from "../stores/authStore";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { PreLoginRouteParams, RouteParams } from "../Router";
import type { RouteProp } from "@react-navigation/native";

type Route = RouteProp<PreLoginRouteParams, "LoginSuccessCallback">;
type Navigation = NativeStackNavigationProp<RouteParams, "LoginSuccessCallback">;

export const LegacyLoginSuccess = () => {
  const route = useRoute<Route>();
  const { deleteAlert, setAlert } = useAlertStore();
  const { api } = useApi();
  const ref = useRef(false);
  const { code, error, error_description, state } = route.params;
  const { setAccessToken, setPermissions, setProfile, setSession } = useAuthStore();
  const navigation = useNavigation<Navigation>();
  const [loginError, setLoginError] = useState<string>();
  const [hasFinishedLoggingIn, setHasFinishedLoggingIn] = useState<boolean>(false);
  const { clear: clearLogoStore } = useOrgLogoStore();

  useEffect(() => {
    const handleCompleteLogin = async () => {
      try {
        const rawJSON = await api.loginSuccessRaw({ code, state });
        const resJSON = await rawJSON.value();
        setAccessToken(resJSON.accessToken);
        setProfile(resJSON.profile as unknown as JwtClaims);
        // reload org logo on login
        clearLogoStore();

        const sessionHeader = rawJSON.raw.headers.get("X-Session-Store");
        if (sessionHeader !== null) {
          setSession(sessionHeader);
        }
        setPermissions([]);

        setHasFinishedLoggingIn(true);
      } catch (err: unknown) {
        if (typeof err === "string") {
          setLoginError(err);
        } else if (err instanceof Error) {
          setLoginError(err.message);
        }
      }
    };

    if (error !== undefined) {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: error_description,
        type: "error",
      });
      navigation.navigate("Landing");

      return;
    }

    if (!ref.current) {
      // eslint-disable-next-line functional/immutable-data
      ref.current = true;
      handleCompleteLogin();
    }
  }, [
    api,
    clearLogoStore,
    code,
    deleteAlert,
    error,
    error_description,
    navigation,
    setAccessToken,
    setAlert,
    setPermissions,
    setProfile,
    setSession,
    state,
  ]);

  useEffect(() => {
    if (hasFinishedLoggingIn) {
      navigation.navigate("OrgSelect");
    }
  }, [hasFinishedLoggingIn, navigation]);

  return (
    <PreLoginLayout>
      {loginError !== undefined && (
        <Heading level="3">
          There was an error logging in, please refresh the page and try again
        </Heading>
      )}
    </PreLoginLayout>
  );
};
